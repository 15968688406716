import React, {useContext, useEffect, useRef, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import FirebaseContext from "../../context/firebaseContext";
import { useParams } from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import DetailHeader from '../../components/DetailHeader';
import {pdfjs} from "react-pdf";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {CommentList} from "../../components/CommentList";
import TextField from "@material-ui/core/TextField";
import {useTranscriptionComments} from "../../hooks/useTranscriptionComments";
import {TranscriptionPdfViewer} from "../../components/TranscriptionPdfViewer";
import {useMediaQuery} from "@material-ui/core";
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles((theme) => {
    return {
        root: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            marginTop: theme.spacing(2),
            padding: theme.spacing(2)
        },
        detailColumn: {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            justifyContent: 'center',
            maxWidth: 450,
        },
        actions: {
            display: 'flex',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2)
        },
    }
});

export function Details() {
    const { firebase } = useContext(FirebaseContext);
    const { id } = useParams();
    const mounted = useRef(true);
    const classes = useStyles();
    const isDesktop = useMediaQuery('(min-width:600px)');

    const [transcription, setTranscription] = useState({});
    const [transcriptionUrl, setTranscriptionUrl] = useState(null);
    const [comments, postTranscriptionComment] = useTranscriptionComments(id);
    const [newComment, setNewComment] = useState('');

    // const handleTranscriptionLike = () => {
    //     firebase.firestore().collection('transcriptions').doc(id).update({
    //         likes: firebase.firestore.FieldValue.increment(1)
    //     });
    //     setTranscription({
    //         ...transcription,
    //         likes: !!transcription.likes ? transcription.likes + 1 : 1
    //     });
    // };

    const handleCommentChange = (event) => {
        setNewComment(event.target.value);
    };

    const handleCommentPost = () => {
        postTranscriptionComment(newComment);
        setNewComment('');
        setTranscription({
            ...transcription,
            comments: !!transcription.comments ? transcription.comments + 1 : 1
        });
    };

    useEffect(() => {
        firebase.firestore().collection('transcriptions').doc(id).get().then((doc) => {
            if (mounted.current) {
                setTranscription(doc.data());
            }
        });
        firebase.storage().ref(`transcriptions/${id}`).listAll().then((res) => {
            if (mounted.current) {
                res.items[0].getDownloadURL().then(url => {
                    setTranscriptionUrl(url)
                });
            }
        });

        return function cleanup() {
            mounted.current = false;
        }

    }, [id, firebase]);

    return (
        <Paper>
            {
                !!transcription.name ?
                    (
                        <div className={classes.root}>
                            <div className={classes.detailColumn}>
                                <DetailHeader transcription={transcription}/>
                                <div className={classes.actions}>
                                    {/*<Button variant='contained' color='primary' onClick={handleTranscriptionLike}>*/}
                                    {/*    Like*/}
                                    {/*</Button>*/}
                                    <Button variant='contained' component='a' download href={transcriptionUrl}>
                                        Download Sheets
                                    </Button>
                                </div>
                                <div className={classes.comments}>
                                    <CommentList comments={comments} />
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <TextField value={newComment} style={{flexGrow: 1}} variant='outlined' multiline onChange={handleCommentChange} label='Add a new comment' />
                                        <Button onClick={handleCommentPost}>Post</Button>
                                    </div>
                                </div>
                            </div>
                            {
                                isDesktop &&
                                (
                                    <div>
                                        <TranscriptionPdfViewer transcriptionUrl={transcriptionUrl}/>
                                    </div>
                                )
                            }

                        </div>
                    ) :
                    (
                        <div className={classes.root}>
                            <CircularProgress />
                        </div>
                    )
            }
        </Paper>
    )
}
