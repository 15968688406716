import React from 'react';
import {usePaging} from "../../hooks/usePaging";
import {Document, Page} from "react-pdf";
import Button from "@material-ui/core/Button";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";

export function TranscriptionPdfViewer(props) {
    const [currentPage, pageCount, incrementPage, decrementPage, setPageCount] = usePaging();

    const onDocumentLoadSuccess = ({numPages}) => {
        setPageCount(numPages);
    };

    return (
        <Document
            file={props.transcriptionUrl}
            onLoadSuccess={onDocumentLoadSuccess}
            style={{ maxWidth: 275 }}
        >
            <Page style={{ maxWidth: 275 }} pageNumber={currentPage}/>
            <div style={{ display: 'flex', justifyContent: 'space-evenly'}}>
                <Button disabled={currentPage === 1} variant="outlined" onClick={decrementPage}>
                    <ChevronLeft />
                </Button>
                <p>Page {currentPage} of {pageCount}</p>
                <Button disabled={currentPage === pageCount} variant='outlined' onClick={incrementPage}>
                    <ChevronRight />
                </Button>
            </div>
        </Document>
    )
}
