import React, {useContext, useEffect, useState} from 'react';
import Paper from "@material-ui/core/Paper";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField";
import TranscriptionCard from "../../components/TranscriptionCard";
import Button from "@material-ui/core/Button";
import FirebaseContext from "../../context/firebaseContext";
import { useHistory } from 'react-router-dom';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import {InsertDriveFile, Attachment} from "@material-ui/icons";
import {useOrgClassGroupFilters} from "../../hooks/useOrgClassGroupFilters";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2)
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: 300
    },
    formElement: {
        margin: theme.spacing(1),
    },
    columnContainer: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    contentColumn: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(2)
    }
}));

function Upload() {
    const classes = useStyles();
    const history = useHistory();
    const { firebase } = useContext(FirebaseContext);

    const [transcriptionForm, setTranscriptionForm] = useState({
        name: null,
        transcriptionType: null,
        competitiveOrg: null,
        competitiveClass: null,
        groupName: null,
        performanceYear: null,
        instrument: null
    });
    const [uploadDisabled, setUploadDisabled] = useState(true);
    const [file, setFile] = useState(null);

    const instruments = [
        {
            label: 'Snare',
            abbr: 'S'
        },
        {
            label: 'Quads',
            abbr: 'Q',
        },
        {
            label: 'Bass',
            abbr: 'B',
        },
        {
            label: 'Cymbals',
            abbr: 'C'
        },
        {
            label: 'Front Ensemble',
            abbr: 'FE'
        }
    ];

    const [
        orgs, currentOrg, setCurrentOrg,
        compClasses, currentClass, setCurrentClass,
        groups, currentGroup, setCurrentGroup
    ] = useOrgClassGroupFilters();

    const transcriptionTypes = [
        'Show Music',
        'Exercise'
    ];

    const handleCompetitiveOrgSelection = (event, newValue) => {
        setCurrentOrg(newValue);
        setTranscriptionForm({
            ...transcriptionForm,
            competitiveOrg: newValue.name,
            competitiveClass: null,
            groupName: null
        });
    };

    const handleCompetitiveClassSelection = (event, newValue) => {
        setCurrentClass(newValue);
        setTranscriptionForm({
            ...transcriptionForm,
            competitiveClass: newValue.abbr,
            groupName: null
        });
    };

    const handleGroupSelection = (event, newValue) => {
        setCurrentGroup(newValue);
        setTranscriptionForm({
            ...transcriptionForm,
            groupName: newValue.name
        });
    };

    const handleTranscriptionNameChange = (event) => {
        setTranscriptionForm({
            ...transcriptionForm,
            name: event.target.value
        });
    };

    const handleTypeChange = (event, newValue) => {
        setTranscriptionForm({
            ...transcriptionForm,
            transcriptionType: newValue
        });
    };

    const handlePerfYearChange = (event) => {
        setTranscriptionForm({
            ...transcriptionForm,
            performanceYear: event.target.value
        });
    };

    const handleInstrumentChange = (change, newValue) => {
        setTranscriptionForm({
            ...transcriptionForm,
            instrument: !!newValue ? newValue.abbr : null
        });
    };

    const handleFileUpload = (event) => {
        setFile(event.target.files[0]);
    };

    const uploadTranscription = () => {
        firebase.firestore().collection('transcriptions').add({
            ...transcriptionForm,
            uploadTime: Date.now(),
            uploadedBy: 'test'
        }).then((docRef) => {
            firebase.storage().ref().child(`transcriptions/${docRef.id}/${file.name}`).put(file).then(() => {
                history.push(`/details/${docRef.id}`)
            });
        });
    };

    useEffect(() => {
        const validateTranscriptionForm = (form) => {
            return form.name
                && form.groupName
                && form.competitiveClass
                && form.competitiveOrg
                && form.performanceYear
                && form.instrument
                && file
        };

        setUploadDisabled(!validateTranscriptionForm(transcriptionForm))
    }, [transcriptionForm, file]);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <div className={classes.columnContainer}>
                    <div className={classes.contentColumn}>
                    <Typography align='left' variant='h5'>Upload New Transcription</Typography>
                    <div className={classes.formContainer}>
                        <Autocomplete
                            onChange={handleCompetitiveOrgSelection}
                            className={classes.formElement}
                            options={orgs}
                            getOptionLabel={(option) => option.name}
                            value={currentOrg}
                            renderInput={(params) => <TextField {...params} label="Competitive Organization" variant="outlined" color='secondary' />}
                        />
                        <Autocomplete
                            onChange={handleCompetitiveClassSelection}
                            className={classes.formElement}
                            options={compClasses}
                            getOptionLabel={(option) => option.name}
                            value={currentClass}
                            disabled={!Boolean(currentOrg)}
                            renderInput={(params) => <TextField {...params} label="Competitive Class" disabled={!Boolean(currentOrg)} variant="outlined" color='secondary' />}
                        />
                        <Autocomplete
                            onChange={handleGroupSelection}
                            className={classes.formElement}
                            getOptionLabel={(option) => option.name}
                            value={currentGroup}
                            options={groups}
                            renderInput={(params) => <TextField {...params} label="Group Name" disabled={!Boolean(currentClass)} variant="outlined" color='secondary' />}
                        />
                        <TextField
                            onChange={handleTranscriptionNameChange}
                            className={classes.formElement}
                            label='Transcription Name'
                            variant='outlined'
                            color='secondary'
                        />
                        <Autocomplete
                            onChange={handleTypeChange}
                            className={classes.formElement}
                            options={transcriptionTypes}
                            renderInput={(params) => <TextField {...params} label="Transcription Type" variant="outlined" color='secondary' />}
                        />
                        <TextField
                            onChange={handlePerfYearChange}
                            className={classes.formElement}
                            label='Performance Year'
                            variant='outlined'
                            color='secondary'
                        />
                        <Autocomplete
                            onChange={handleInstrumentChange}
                            className={classes.formElement}
                            options={instruments}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => <TextField {...params} label="Instrument" variant="outlined" color='secondary' />}
                        />
                    </div>
                </div>
                    <div className={classes.contentColumn}>
                    <Typography align='left' variant='h5'>Preview</Typography>
                    <TranscriptionCard transcription={transcriptionForm}/>
                    <Typography align='left' variant='h5'>Attached Files</Typography>
                    <List>
                                <ListItem>
                                    <ListItemAvatar>
                                        { !!file ? (<InsertDriveFile />) : (<Attachment />)}
                                    </ListItemAvatar>
                                    <ListItemText>
                                        { !!file ? file.name : 'No file selected' }
                                    </ListItemText>
                                </ListItem>
                    </List>
                    <Button
                        component='label'
                    >
                        + Attach Music
                        <input
                            onChange={handleFileUpload}
                            type='file'
                            style={{ display: 'none' }}
                            />
                    </Button>
                </div>
                </div>
                <Button onClick={uploadTranscription} disabled={uploadDisabled} variant='contained' color='secondary'>Confirm and Upload</Button>
            </Paper>

        </div>
    )
}

export default Upload;
