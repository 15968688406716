import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import './AppRoot.css';
import AppHeader from "../../components/AppHeader";
import Home from "../Home";
import Upload from "../Upload";
import createFirebaseContext from "../../utils/initFirebase";
import {FirebaseProvider} from "../../context/firebaseContext";
import Container from "@material-ui/core/Container";
import {createMuiTheme} from "@material-ui/core";
import {ThemeProvider} from "@material-ui/styles";
import {Details} from "../Details";

const theme = createMuiTheme({
    palette: {
        type: 'light'
    }
});

function AppRoot() {

    const firebaseRef = createFirebaseContext();

    return (
        <div className="App" style={{height: '100vh', background: theme.palette.background.default, overflow: 'auto'}}>
            <ThemeProvider theme={theme}>
                <FirebaseProvider value={firebaseRef}>
                    <Router>
                        <AppHeader/>
                        <Container maxWidth='xl' style={{paddingTop: 64}}>
                            <Switch>
                                <Route path='/details/:id'>
                                    <Details />
                                </Route>
                                <Route path='/upload'>
                                    <Upload />
                                </Route>
                                <Route path='/'>
                                    <Home />
                                </Route>
                            </Switch>
                        </Container>
                    </Router>
                </FirebaseProvider>
            </ThemeProvider>
        </div>
    );
}

export default AppRoot;
