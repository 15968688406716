import React from 'react';
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => {return {
    root: {
        display: 'flex',
    },
    cardContentRow: {
        display: 'flex',
        alignItems: 'center'
    },
    cardContainer: {
        display: 'flex',
    },
    avatarColumn: {
        width: 40,
        marginRight: 16
    },
    detailColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    divider: {
        margin: 8
    },
    themedAvatar: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        background: theme.palette.primary.main
    }
}});

function DetailHeader(props) {
    const classes = useStyles();

    const defaultValueFormatter = (value, defaultText) => {
        if (!value) return defaultText;
        return value;
    };

    return (
        <div className={classes.root}>
            <div className={classes.detailColumn}>
                <div>
                    <Typography align='left' variant='h4'>{defaultValueFormatter(props.transcription.name, 'New Transcription')}</Typography>
                    <Typography align='left' variant='h6'>{defaultValueFormatter(props.transcription.groupName, 'Group')} - {defaultValueFormatter(props.transcription.performanceYear, 'Year')}</Typography>
                    <Typography align='left' variant='body1'>{defaultValueFormatter(props.transcription.transcriptionType, 'Transcription Type')}</Typography>
                </div>
                <div>
                    <Typography align='left' variant='body2'>{!!props.transcription.comments ? props.transcription.comments : '0'} Comments, {!!props.transcription.likes ? props.transcription.likes : '0'} Likes</Typography>
                </div>
            </div>
        </div>
    )
}

export default DetailHeader;
