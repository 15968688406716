import React, {useContext} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {Link} from "react-router-dom";
import FirebaseContext from "../../context/firebaseContext";
import LoginDialog from "../LoginDialog";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {useMediaQuery} from "@material-ui/core";
import headerLogo from '../../assets/images/transcribus_logo_header.png';

const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1
        },
        toolbarLogo: {
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'flex-start'
        },
        navLink: {
            textDecoration: 'none',
            color: theme.palette.background.paper,
        },
        navLinkMobile: {
            textDecoration: 'none',
            color: theme.palette.text.primary,
        },
    })
);

function AppHeader() {
    const classes = useStyles();

    const isDesktop = useMediaQuery('(min-width:600px)');

    const { firebase } = useContext(FirebaseContext);

    const [open, setOpen] = React.useState(false);
    const [currentUser, setCurrentUser] = React.useState(firebase.auth().currentUser);
    const [anchorEl, setAnchorEl] = React.useState(null);

    firebase.auth().onAuthStateChanged((user) => {
        setCurrentUser(user);
        if (!!user) {
            setOpen(false);
        }
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleLoginClick = () => {
        setOpen(true);
    };

    const handleLogout = () => {
        firebase.auth().signOut().then(() => {
            setCurrentUser(null);
        });
        setAnchorEl(null);
    };

    const handleProfileClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileClose = () => {
        setAnchorEl(null)
    };

    return (
        <div className={classes.root}>
            <AppBar position='fixed'>
                <Toolbar>
                    <div className={classes.toolbarLogo}>
                        <img src={headerLogo} alt='' />
                    </div>
                    {
                        isDesktop ? (
                            <div>
                                <Button>
                                    <Link to='/' className={classes.navLink}>
                                        Home
                                    </Link>
                                </Button>
                                <Button>
                                    <Link to='/upload' className={classes.navLink}>
                                        Upload
                                    </Link>
                                </Button>
                                <Button>
                                    <Link to='/browse' className={classes.navLink}>
                                        Browse
                                    </Link>
                                </Button>
                                <Button variant='contained' color='secondary' onClick={!!currentUser ? handleProfileClick : handleLoginClick}>
                                    { !!currentUser ? currentUser.displayName : 'Login' }
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleProfileClose}
                                >
                                    <MenuItem onClick={handleProfileClose}>Profile</MenuItem>
                                    <MenuItem onClick={handleProfileClose}>My account</MenuItem>
                                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                                </Menu>
                            </div>
                        ) : (
                            <div>
                                <Button variant='contained' color='secondary' onClick={handleProfileClick}>
                                    Menu
                                </Button>
                                <Menu
                                    id="simple-menu"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={handleProfileClose}
                                >
                                    <MenuItem onClick={handleProfileClose}>
                                        <Link to='/' className={classes.navLinkMobile}>
                                            Home
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleProfileClose}>
                                        <Link to='/upload' className={classes.navLinkMobile}>
                                            Upload
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={handleLogout}>
                                        <Link to='/browse' className={classes.navLinkMobile}>
                                            Browse
                                        </Link>
                                    </MenuItem>
                                    <MenuItem onClick={!!currentUser ? handleLogout : handleLoginClick}>
                                        { !!currentUser ? 'Logout' : 'Login' }
                                    </MenuItem>
                                </Menu>
                            </div>
                        )
                    }
                    <LoginDialog onClose={handleClose} open={open} />
                </Toolbar>
            </AppBar>
        </div>
    )
}

export default AppHeader;
