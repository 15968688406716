import {useContext, useEffect, useState} from "react";
import FirebaseContext from "../context/firebaseContext";


export function useOrgClassGroupFilters() {
    const { firebase } = useContext(FirebaseContext);

    const [orgs, setOrgs] = useState([]);
    const [classes, setClasses] = useState([]);
    const [groups, setGroups] = useState([]);

    const [currentOrg, setCurrentOrg] = useState(null);
    const [currentClass, setCurrentClass] = useState(null);
    const [currentGroup, setCurrentGroup] = useState(null);

    useEffect(() => {
        let mounted = true;

        firebase.firestore().collection('organizations')
            .orderBy('name')
            .get().then((querySnapshot) => {
                if (mounted) {
                    const orgResp = [];
                    querySnapshot.forEach(doc => orgResp.push({
                        id: doc.id,
                        ...doc.data()
                    }));
                    setOrgs(orgResp);
                }
            }
        );

        return function cleanup() {
            mounted = false;
        };
    }, [firebase]);

    useEffect(() => {
        let mounted = true;

        if (currentOrg) {
            firebase.firestore().collection('organizations')
                .doc(currentOrg.id)
                .collection('classes')
                .orderBy('order')
                .get().then((querySnapshot) => {
                    if (mounted) {
                        const classResp = [];
                        querySnapshot.forEach(doc => classResp.push({
                            id: doc.id,
                            ...doc.data()
                        }));
                        setClasses(classResp);
                    }
                }
            )
        } else {
            if (mounted) {
                setClasses([]);
            }
        }

        return function cleanup() {
            mounted = false;
        };
    }, [currentOrg, firebase]);

    useEffect(() => {
        let mounted = true;

        if (currentOrg && currentClass) {
            firebase.firestore().collection('organizations')
                .doc(currentOrg.id)
                .collection('classes')
                .doc(currentClass.id)
                .collection('groups')
                .orderBy('name')
                .get().then((querySnapshot) => {
                    if (mounted) {
                        const groupResp = [];
                        querySnapshot.forEach(doc => groupResp.push({
                            id: doc.id,
                            ...doc.data()
                        }));
                        setGroups(groupResp)
                    }
                }
            )
        } else {
            if (mounted) {
                setGroups([]);
            }
        }

        return function cleanup() {
            mounted = false;
        }
    }, [currentOrg, currentClass, firebase]);

    return [orgs, currentOrg, setCurrentOrg, classes, currentClass, setCurrentClass, groups, currentGroup, setCurrentGroup];
}
