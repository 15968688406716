import {useContext, useEffect, useRef, useState} from "react";
import FirebaseContext from "../context/firebaseContext";

export function useTranscriptionComments(transcriptionId) {
    const { firebase } = useContext(FirebaseContext);

    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState(null);

    const mounted = useRef(true);

    const postTranscriptionComment = (newComment) => {
        const newCommentReq = {
            text: newComment,
            author: firebase.auth().currentUser.displayName,
            timestamp: firebase.firestore.Timestamp.now()
        };

        setNewComment(newCommentReq);
    };

    useEffect(() => {
        if (newComment) {
            firebase.firestore().collection('transcriptions')
                .doc(transcriptionId)
                .collection('comments')
                .add(newComment).then(() => {
                    if (mounted.current) {
                        setComments(c => [...c, newComment]);
                        firebase.firestore().collection('transcriptions')
                            .doc(transcriptionId)
                            .update({
                                    comments: firebase.firestore.FieldValue.increment(1)
                                }
                            );
                    }
                }
            );
        }
    }, [newComment, transcriptionId, firebase]);

    useEffect(() => {
        firebase.firestore().collection('transcriptions')
            .doc(transcriptionId)
            .collection('comments')
            .orderBy('timestamp')
            .get().then(querySnapshot => {
                if (mounted.current) {
                    const respComments = [];
                    querySnapshot.forEach(doc => respComments.push(doc.data()));
                    setComments(respComments);
                }
        });
    });

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        }
    }, []);

    return [comments, postTranscriptionComment];
}
