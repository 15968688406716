import React from 'react';
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";

const useStyles = makeStyles(theme => {return {
    root: {
        width: 275,
        height: 185,
        margin: theme.spacing(2)
    },
    media: {
        height: 140
    },
    cardContentRow: {
        display: 'flex',
        alignItems: 'center'
    },
    cardContainer: {
        display: 'flex',
    },
    avatarColumn: {
        width: 40,
        marginRight: 16
    },
    detailColumn: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },
    divider: {
        margin: 8
    },
    themedAvatar: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        background: theme.palette.primary.main
    }
}});

function TranscriptionCard(props) {
    const classes = useStyles();

    const defaultValueFormatter = (value, defaultText) => {
        if (!value) return defaultText;
        return value;
    };

    return (
        <Card onClick={!!props.onClick ? props.onClick : () => {}} variant='elevation' elevation={4} className={classes.root}>
            <CardActionArea>
                <CardContent className={classes.cardContainer}>
                    <div className={classes.avatarColumn}>
                        <Avatar className={classes.themedAvatar}>
                            {defaultValueFormatter(props.transcription?.instrument, '--')}
                        </Avatar>
                        <Divider className={classes.divider} />
                        <Avatar className={classes.themedAvatar}>
                            {defaultValueFormatter(props.transcription?.competitiveOrg, '--')}
                        </Avatar>
                        <Divider className={classes.divider} />
                        <Avatar className={classes.themedAvatar}>
                            {defaultValueFormatter(props.transcription?.competitiveClass, '--')}
                        </Avatar>
                    </div>
                    <div className={classes.detailColumn}>
                        <div>
                            <Typography align='left' variant='h6'>{defaultValueFormatter(props.transcription.name, 'New Transcription')}</Typography>
                            <Typography align='left' variant='body1'>{defaultValueFormatter(props.transcription.groupName, 'Group')} - {defaultValueFormatter(props.transcription.performanceYear, 'Year')}</Typography>
                            <Typography align='left' variant='body2'>{defaultValueFormatter(props.transcription.transcriptionType, 'Transcription Type')}</Typography>
                        </div>
                        <div>
                            <Typography align='right' variant='body2'>{!!props.transcription.comments ? props.transcription.comments : '0'} Comments, {!!props.transcription.likes ? props.transcription.likes : '0'} Likes</Typography>
                        </div>
                    </div>
                </CardContent>
            </CardActionArea>
        </Card>
    )
}

export default TranscriptionCard;
