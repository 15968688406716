import {useState} from "react";


export function usePaging() {
    const [pageCount, setPageCount] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);

    const incrementPage = () => {
        if (currentPage < pageCount) {
            setCurrentPage(currentPage + 1);
        }
    };

    const decrementPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    return [currentPage, pageCount, incrementPage, decrementPage, setPageCount];
}
