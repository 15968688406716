import React from 'react';
import {CommentCard} from "../CommentCard";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    commentList: {
        maxHeight: 400,
        overflowY: 'auto',
    }
});

export function CommentList(props) {
    const classes = useStyles();

    return (
        <div>
            <Typography align='left' variant='h6'>Comments</Typography>
            {
                props.comments?.length ?
                    (
                        <List className={classes.commentList}>
                            {
                                props.comments.map(comment => (
                                    <CommentCard comment={comment}/>
                                ))
                            }
                        </List>
                    ) :
                    (<></>)
            }

        </div>
    )
}
