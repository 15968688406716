import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";
import Avatar from "@material-ui/core/Avatar";

export function CommentCard(props) {

    return (
        <ListItem alignItems='flex-start'>
            <ListItemAvatar>
                <Avatar>A</Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={`Posted by ${props.comment.author}`}
                secondary={props.comment.text}
            />
        </ListItem>
    )
}
