import React from 'react';
import TranscriptionCard from "../TranscriptionCard";
import { makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => { return {
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(2),
        padding: theme.spacing(2)
    },
    cardList: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        alignContent: 'flex-start'
    }
}})

function CardList(props) {
    const classes = useStyles();
    const history = useHistory();

    function handleClick(id) {
        history.push(`/details/${id}`);
    };

    return (
        <Paper className={classes.root} elevation={1} variant='outlined'>
            <div className={classes.listTitle}>
                <Typography align='left' variant='h5' color='textPrimary'>
                    { props.title }
                </Typography>
            </div>
            <div className={classes.cardList}>
                {
                    props.items.length ?
                    props.items.map(item => <TranscriptionCard onClick={() => handleClick(item.id)} key={item.id} transcription={item}/>) :
                        (<CircularProgress />)
                }
            </div>
        </Paper>
    )
}

export default CardList;
