import React, {useContext} from 'react'
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import FirebaseContext from "../../context/firebaseContext";
import {StyledFirebaseAuth} from "react-firebaseui";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    authContainer: {
        minHeight: 220,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
});

function LoginDialog(props) {
    const classes = useStyles();

    const { onClose, open } = props;
    const { firebase, uiConfig } = useContext(FirebaseContext);

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog onClose={handleClose} open={open} maxWidth='xs' fullWidth={true}>
            <DialogTitle>Sign Up or Log In</DialogTitle>
            <div className={classes.authContainer}>
                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
            </div>

        </Dialog>
    )
}

export default LoginDialog;
