let firebase = require('firebase');

export default function createFirebaseContext() {
    const firebaseConfig = {
        apiKey: "AIzaSyCk_m50KajxOa6bcQeLXo2UHsoaJ_7SD4M",
        authDomain: "transcribus-54a94.firebaseapp.com",
        databaseURL: "https://transcribus-54a94.firebaseio.com",
        projectId: "transcribus-54a94",
        storageBucket: "transcribus-54a94.appspot.com",
        messagingSenderId: "310837468873",
        appId: "1:310837468873:web:96d26b1554c9fc05577718",
        measurementId: "G-FXWEMSCMB5"
    };

    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
    firebase.performance();

    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        signInOptions: [
            firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => false
        }
    };

    return {
        firebase,
        uiConfig
    };
}

