import React, {useContext, useEffect, useRef, useState} from 'react';
import CardList from "../../components/CardList";
import FirebaseContext from "../../context/firebaseContext";
import {useMediaQuery} from "@material-ui/core";


function Home() {
    const isDesktop = useMediaQuery('(min-width:600px)');

    const mounted = useRef(true);

    const { firebase } = useContext(FirebaseContext);

    const [popularUploads, setPopularUploads] = useState([]);
    const [latestUploads, setLatestUploads] = useState([]);

    useEffect(() => {
        const recordLimit = isDesktop ? 5 : 3;

        firebase.firestore().collection('transcriptions')
            .limit(recordLimit)
            .get()
            .then(querySnapshot => {
                if (mounted.current) {
                    console.log('Requested transcriptions');
                    const result = [];
                    querySnapshot.forEach(doc => result.push(
                        {
                            id: doc.id,
                            ...doc.data()
                        }
                    ));
                    setLatestUploads(result);
                }
            });

        firebase.firestore().collection('transcriptions')
            .limit(recordLimit)
            .get()
            .then(querySnapshot => {
                if (mounted.current) {
                    const result = [];
                    querySnapshot.forEach(doc => result.push(
                        {
                            id: doc.id,
                            ...doc.data()
                        }
                    ));
                    setPopularUploads(result);
                }
            });
    }, [firebase, isDesktop]);

    useEffect(() => {
        return function cleanup() {
            mounted.current = false;
        }
    }, []);

    return (
        <div>
            <CardList title='Popular Uploads' items={popularUploads}/>
            <CardList title='Latest Uploads' items={latestUploads}/>
        </div>
    );
}

export default Home;
